import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import { QueryDrawer } from './query-drawer'

const LoginBox = loadable(() => import('./login-box'))

export function LoginDrawer() {
  const location = ReactRouter.useLocation()
  const translate = Herz.I18n.useTranslate()

  const { authenticated } = Herz.Auth.useSession()
  const [returningPlayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )
  // Login is available on the landing-page as a returning user.
  if (authenticated || (returningPlayer && location.pathname === '/')) {
    return null
  }

  return (
    <QueryDrawer activeQueryName="login" title={translate('login-page.title')}>
      <Common.Box pb={6} color="g-text">
        <LoginBox />
      </Common.Box>
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(['login-page.title'], LoginDrawer)

// for @loadable/components
export default LoginDrawer
